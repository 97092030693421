import React from 'react';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import { PanelContainer } from '../../../components/panel';
import { color } from '../../../styles';
import { DEFAULT_RESIDENT_FORM_VALUE, SNOOZETERM_EN, SNOOZETERM_JA } from '../../../constants';
import { ResidentSettingForm } from './residentSettingForm';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { selfDataSelector } from '../../../redux/config/selfData';
import { showLifeContentSelector } from '../../../redux/config/currentNursingHome';
import { ICreateResidentParams } from '../../../types/api/resident/createResident';
import { IGetResidentRes } from '../../../types/api/resident/getResident';
import { IGetGoodTreeRes } from '../../../types/api/good-tree/getGoodTree';
import { IGetNursingHomeRes } from '../../../types/api/nursingHome/getNursingHome';
import { IUpdateResidentParams } from '../../../types/api/resident/updateResident';
import { IGetDiaperRes } from '../../../types/api/care-settings/getDiaper';
import { IGetDfreeDevicesRes } from '../../../types/api/dfree-devices/getDfreeDevices';
import { IGetLaxativeRes } from '../../../types/api/care-settings/getLaxative';
import { convertTzToUtcHourString, convertUtcToTzHourString } from '../../../utils/dateutil';
import { Button } from '../../../components/mui/button';
import { OutlineButton } from '../../../components/outlineButton';
import { Loading } from '../../../components/mui/Loading';
import { useLoading } from '../../../contexts/LoadingContext';
import { Dialog } from '../../../components/mui/dialog';

dayjs.extend(isSameOrAfter);

interface Props {
  isEdit?: boolean;
  residentId?: number;
}

const PanelButtonContainer = css`
  display: flex;
  gap: 0 16px;
`;

const LowerButtonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(Button)`
  color: ${color.red};
`;

const DialogText = css`
  margin-bottom: 16px;
  font-size: 14px;
`;

const DialogAlertText = css`
  color: ${color.red};
  font-size: 14px;
  font-weight: bold;
`;

const DialogContainer = css`
  margin: 8px;
  text-align: center;
`;

const EmptyContainer = css`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 24px;
  background-color: ${color.bg_light_green};
  display: flex;
`;

export const ResidentSetting: React.FC<Props> = ({ isEdit = false, residentId }) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: DEFAULT_RESIDENT_FORM_VALUE,
    mode: 'onBlur',
  });
  const {
    purpose,
    lastName,
    careSettings,
    bigBladderNotification,
    urinationNotification,
    gotUpNotification,
    bigBladderSnoozeTerm,
    urinationSnoozeTerm,
    gotUpSnoozeTerm,
    bigBladderSnoozePeriod,
    urinationSnoozePeriod,
    gotUpSnoozePeriod,
  } = useWatch({
    control,
  });
  const { selfUnits } = useSelector(selfDataSelector);
  const showLifeContent = useSelector(showLifeContentSelector);
  const [selectData, setSelectData] = React.useState<{
    pants: IGetDiaperRes[];
    pads: IGetDiaperRes[];
    devices: IGetDfreeDevicesRes[];
    laxatives: IGetLaxativeRes[];
    goodTreeList: IGetGoodTreeRes[];
  }>();
  const [deviceId, setDeviceId] = React.useState<string>('');
  const [careSchedule, setCareSchedule] = React.useState<string[]>([]);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isOpenAttachedDialog, setOpenAttachedDialog] = React.useState(false);
  const [resident, setResident] = React.useState<IGetResidentRes>();
  const [nursingHome, setNursingHome] = React.useState<IGetNursingHomeRes>();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [onPreSend, setPreOnSend] = React.useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const fetchResident = React.useCallback(async () => {
    if (!isEdit) return;
    try {
      showLoading();
      let resident = await api.get('/residents/:residentId', { params: { residentId } });
      const careSchedule = await api.get('/residents/:residentId/care-schedule', { params: { residentId } });
      /* eslint-disable-next-line no-extra-boolean-cast */
      if (!!careSchedule.careTimings.length) {
        const localCareScheduleTimings = careSchedule.careTimings.map((timing) => {
          return dayjs()
            .hour(+timing.slice(0, 2) + dayjs().utcOffset() / 60)
            .minute(0)
            .second(0)
            .local()
            .format('HH:mm:ss[Z]');
        });
        setCareSchedule(localCareScheduleTimings);
      }
      if (resident) {
        resident = {
          ...resident,
          beginGotUpCheckTerm: resident.beginGotUpCheckTerm
            ? convertUtcToTzHourString(resident.beginGotUpCheckTerm)
            : '19:00:00Z',
          endGotUpCheckTerm: resident.endGotUpCheckTerm
            ? convertUtcToTzHourString(resident.endGotUpCheckTerm)
            : '07:00:00Z',
          // スヌーズ間隔がnullの場合はなしを補完している
          bigBladderSnoozeTerm: resident.bigBladderSnoozeTerm ? resident.bigBladderSnoozeTerm : 0,
          urinationSnoozeTerm: resident.urinationSnoozeTerm ? resident.urinationSnoozeTerm : 0,
          gotUpSnoozeTerm: resident.gotUpSnoozeTerm ? resident.gotUpSnoozeTerm : 0,
          // スヌーズ終了がnullの場合はデフォルト値(1時間)を補完している
          bigBladderSnoozePeriod: resident.bigBladderSnoozePeriod ? resident.bigBladderSnoozePeriod : 60,
          urinationSnoozePeriod: resident.urinationSnoozePeriod ? resident.urinationSnoozePeriod : 60,
          gotUpSnoozePeriod: resident.gotUpSnoozePeriod ? resident.gotUpSnoozePeriod : 60,
        };
      }
      setDeviceId(String(resident.dfreeDeviceId));
      setResident(resident);
      reset({ ...resident });
      hideLoading();
    } catch {
      history.push('/');
    }
  }, []);

  const fetchNursingHome = React.useCallback(async () => {
    try {
      showLoading();
      const nursingHome = await api.get('/nursing-homes/:nursingHomeId', { params: { nursingHomeId } });
      setNursingHome(nursingHome);
      hideLoading();
    } catch {
      history.push('/');
    }
  }, [nursingHomeId]);

  // フォームのセレクトに必要なデータを取得
  const fetchSelectData = React.useCallback(async () => {
    const diapersResponse = await api.get('/nursing-homes/:nursingHomeId/diapers', { params: { nursingHomeId } });
    const pants = diapersResponse.filter((diapersResponse) => diapersResponse.diaperShape === 'outer');
    const pads = diapersResponse.filter((diapersResponse) => diapersResponse.diaperShape === 'inner');
    const devices = await api.get('/nursing-homes/:nursingHomeId/dfree-devices', { params: { nursingHomeId } });
    const laxatives = await api.get('/nursing-homes/:nursingHomeId/laxatives', { params: { nursingHomeId } });
    const goodTreeList = await api.get('/nursing-homes/:nursingHomeId/residents/external/good-tree', {
      params: { nursingHomeId },
    });
    setSelectData({ pants, pads, devices, laxatives, goodTreeList });
  }, [nursingHomeId]);

  const handleChangeDeviceId = React.useCallback((id: string) => {
    setDeviceId(id);
  }, []);

  const handleChangeCareSchedule = React.useCallback(
    (schedule: string) => {
      setCareSchedule((prev) => {
        if (prev.length <= 0) {
          return [...prev, schedule];
        }
        const findSchedule = prev.find((v) => v === schedule);
        if (findSchedule) {
          return prev.filter((v) => v !== schedule);
        } else {
          return [...prev, schedule];
        }
      });
    },
    [careSchedule]
  );

  // Selectコンポーネントのvalueが0のものはnullとして扱う
  const formatSnooze = (value: number | null) => {
    if (value === 0) return null;
    else return value;
  };

  const preSubmit = async (data: ICreateResidentParams | IUpdateResidentParams) => {
    /* eslint-disable-next-line no-extra-boolean-cast */
    const device = !!deviceId
      ? selectData?.devices.find((device) => device.id === +deviceId)
      : selectData?.devices.find((device) => device.id === resident?.dfreeDeviceId);
    const currentResidentId = device?.residentId;

    currentResidentId && currentResidentId != residentId ? handleOpenAttachedDialog() : submit(data);
  };

  const submit = async (data: ICreateResidentParams | IUpdateResidentParams) => {
    setOnSend(true);

    const unit = selfUnits.find((unit) => unit.id === data.nursingHomeUnitId);
    if (!unit) {
      showSnackbar(t('unit', 'ユニット') + t('isRequired', 'は必須入力です。'), 'error');
      setOnSend(false);
      return;
    }

    /* eslint-disable-next-line no-extra-boolean-cast */
    const device = !!deviceId
      ? selectData?.devices.find((device) => device.id === +deviceId)
      : selectData?.devices.find((device) => device.id === resident?.dfreeDeviceId);

    const params: ICreateResidentParams | IUpdateResidentParams = {
      ...data,
      beginGotUpCheckTerm: convertTzToUtcHourString(data.beginGotUpCheckTerm),
      endGotUpCheckTerm: convertTzToUtcHourString(data.endGotUpCheckTerm),
      bigBladderSnoozeTerm: formatSnooze(data.bigBladderSnoozeTerm),
      urinationSnoozeTerm: formatSnooze(data.urinationSnoozeTerm),
      gotUpSnoozeTerm: formatSnooze(data.gotUpSnoozeTerm),
      bigBladderSnoozePeriod: formatSnooze(data.bigBladderSnoozePeriod),
      urinationSnoozePeriod: formatSnooze(data.urinationSnoozePeriod),
      gotUpSnoozePeriod: formatSnooze(data.gotUpSnoozePeriod),
      careSettings: {
        ...data.careSettings,
        beginDiaperMiddleHour:
          data.careSettings.beginDiaperMiddleHour === '-' ? null : data.careSettings.beginDiaperMiddleHour,
        endDiaperMiddleHour:
          data.careSettings.endDiaperMiddleHour === '-' ? null : data.careSettings.endDiaperMiddleHour,
        beginDiaperNightHour:
          data.careSettings.beginDiaperNightHour === '-' ? null : data.careSettings.beginDiaperNightHour,
        endDiaperNightHour: data.careSettings.endDiaperNightHour === '-' ? null : data.careSettings.endDiaperNightHour,
      },
      nursingHomeUnitName: unit.name,
      /* eslint-disable-next-line no-extra-boolean-cast */
      dfreeDeviceId: !!deviceId ? +deviceId : null,
    };

    try {
      let currentResidentId = null;
      if (isEdit) {
        await api.put('/residents/:residentId', { residentId, ...params });
        currentResidentId = residentId;
      } else {
        const res = await api.post('/nursing-homes/:nursingHomeId/residents', { ...params });
        currentResidentId = res.id;
      }
      if (careSchedule.length > 0) {
        const utcCareSchedule = careSchedule.map((schedule) => {
          return dayjs()
            .hour(+schedule.slice(0, 2) - dayjs().utcOffset() / 60)
            .minute(0)
            .second(0)
            .local()
            .format('HH:mm:ss[Z]');
        });
        await api.put('/residents/:residentId/care-schedule', {
          residentId: currentResidentId,
          careTimings: utcCareSchedule,
        });
      }
      if (careSchedule.length == 0) {
        await api.put('/residents/:residentId/care-schedule', {
          residentId: currentResidentId,
          careTimings: [],
        });
      }
      setOnSend(false);
      showSnackbar(
        isEdit
          ? t('residentHasBeenUpdated', '入居者情報を更新しました')
          : t('residentHasBeenAdded', '入居者を登録しました')
      );
      history.push('/');
    } catch (e) {
      setOnSend(false);
      showSnackbar(t('somethingWentWrong', 'エラーが発生しました'));
    }
  };

  const isAfternoonEndBeforeNightStart = (value: string | null) => {
    if (
      !value ||
      value === '-' ||
      !careSettings ||
      !careSettings.endDiaperMiddleHour ||
      !careSettings.beginDiaperNightHour
    )
      return false;
    return dayjs()
      .hour(+value.slice(0, 2))
      .isAfter(dayjs().hour(+careSettings.beginDiaperNightHour.slice(0, 2)));
  };

  const deleteResident = React.useCallback(async () => {
    await api.delete('/residents/:residentId', { params: { residentId } });
    showSnackbar(t('residentHasBeenDeleted', '入居者を削除しました'));
    history.push('/');
  }, []);

  const handleNavigateLifeSettingsPage = React.useCallback(() => {
    history.push('./life/edit');
  }, []);

  const handleOpenDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(true);
  }, []);

  const handleCloseDeleteDialog = React.useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenAttachedDialog = React.useCallback(() => {
    setOpenAttachedDialog(true);
  }, []);

  const handleCloseAttachedDialog = React.useCallback(() => {
    setOpenAttachedDialog(false);
  }, []);

  const memoFormData = React.useMemo(() => {
    if (!selectData) return;
    const pants = selectData.pants.map((pant) => {
      return { key: pant.id, value: pant.diaperName };
    });
    const pads = selectData.pads.map((pad) => {
      return { key: pad.id, value: pad.diaperName };
    });
    const devices = selectData.devices;
    const laxatives = selectData.laxatives.map((laxative) => {
      return { key: laxative.id, value: laxative.name };
    });
    const goodTreeList = selectData.goodTreeList.map((goodTree) => {
      return { key: goodTree.id, value: `${goodTree.sei} ${goodTree.mei}` };
    });
    return { pants, pads, devices, laxatives, goodTreeList };
  }, [selectData]);

  const handleChangePurpose = (purpose: string) => {
    if (purpose === 'toilet') {
      setValue('bigBladderNotification', true);
      setValue('bladderThresholdPercentage', 60);
      setValue('urinationNotification', false);
      setValue('gotUpNotification', false);
      setValue('zeroResetNotification', false);
    }
    if (purpose === 'diaper') {
      setValue('bigBladderNotification', false);
      setValue('bladderThresholdPercentage', 60);
      setValue('urinationNotification', true);
      setValue('gotUpNotification', false);
      setValue('zeroResetNotification', false);
    }
    if (purpose === 'monitoring') {
      setValue('bigBladderNotification', false);
      setValue('bladderThresholdPercentage', 60);
      setValue('urinationNotification', false);
      setValue('gotUpNotification', false);
      setValue('zeroResetNotification', false);
    }
  };

  const handleChangeSnoozeTerm = (type: string, value: string | number) => {
    if (type === 'bigBladder' && bigBladderSnoozePeriod && +value > +bigBladderSnoozePeriod) {
      setValue('bigBladderSnoozePeriod', +value);
    }
    if (type === 'urination' && urinationSnoozePeriod && +value > +urinationSnoozePeriod) {
      setValue('urinationSnoozePeriod', +value);
    }
    if (type === 'gotUp' && gotUpSnoozePeriod && +value > +gotUpSnoozePeriod) {
      setValue('gotUpSnoozePeriod', +value);
    }
  };

  const handleChangeSnoozePeriod = (type: string, value: string | number) => {
    if (type === 'bigBladder' && bigBladderSnoozeTerm && +value < +bigBladderSnoozeTerm) {
      setValue('bigBladderSnoozeTerm', +value);
    }
    if (type === 'urination' && urinationSnoozeTerm && +value < +urinationSnoozeTerm) {
      setValue('urinationSnoozeTerm', +value);
    }
    if (type === 'gotUp' && gotUpSnoozeTerm && +value < +gotUpSnoozeTerm) {
      setValue('gotUpSnoozeTerm', +value);
    }
  };

  const handleAddUnitButton = () => {
    history.push(`/nursinghome/${nursingHomeId}/settings/unit`);
  };

  React.useEffect(() => {
    if (!nursingHomeId) return;
    if (isEdit) {
      fetchResident();
    }
    fetchNursingHome();
    fetchSelectData();
    reset({ ...DEFAULT_RESIDENT_FORM_VALUE, nursingHomeId: +nursingHomeId });
  }, [nursingHomeId]);

  return (
    <>
      <PanelContainer
        title={isEdit ? t('editResident', '入居者編集') : t('addResident', '入居者追加')}
        fixable={true}
        rightElement={
          <div css={PanelButtonContainer}>
            <Button variant='contained' onClick={handleSubmit(preSubmit)} type='submit' disabled={onSend}>
              {isEdit ? t('save', '保存') : t('addResident', '入居者追加')}
            </Button>
            {isEdit && (
              <>
                {showLifeContent && (
                  <OutlineButton onClick={handleNavigateLifeSettingsPage}>{'LIFE利用者設定'}</OutlineButton>
                )}
                <DeleteButton onClick={handleOpenDeleteDialog}>{t('deleteResident', '入居者削除')}</DeleteButton>
              </>
            )}
          </div>
        }
      >
        {selfUnits.length > 0 ? (
          <>
            {memoFormData && nursingHome ? (
              <form onSubmit={handleSubmit(preSubmit)}>
                <ResidentSettingForm
                  control={control}
                  units={selfUnits}
                  formSelectLists={memoFormData}
                  careSchedule={careSchedule}
                  deviceId={deviceId}
                  isDisplayBigBladderOption={bigBladderNotification}
                  isDisplayUrinationOption={urinationNotification}
                  isDisplayGotUpOption={gotUpNotification}
                  nameError={!!errors.name}
                  lastNameError={!!errors.lastName}
                  unitError={!!errors.nursingHomeUnitId}
                  endDiaperMiddleHourError={!!errors.careSettings?.endDiaperMiddleHour}
                  isAfternoonEndBeforeNightStart={isAfternoonEndBeforeNightStart}
                  onChangeCareSchedule={handleChangeCareSchedule}
                  onChangeDeviceId={handleChangeDeviceId}
                  onChangePurpose={handleChangePurpose}
                  onChangeSnoozeTerm={handleChangeSnoozeTerm}
                  onChangeSnoozePeriod={handleChangeSnoozePeriod}
                  nursingHome={nursingHome}
                />
                <div css={LowerButtonContainer}>
                  <Button variant='contained' type='submit' disabled={onSend}>
                    {isEdit ? t('save', '保存') : t('addResident', '入居者追加')}
                  </Button>
                </div>
              </form>
            ) : (
              <>
                <Loading />
              </>
            )}
          </>
        ) : (
          <div css={EmptyContainer}>
            <span>
              {t('whenRegisteringANewResident', '新規に入居者を登録する際は、')}
              {t('AUnitIsRequiredInAdvance', '事前にユニットの登録が必要です。')}
            </span>
            <span>{t('PleaseRegisterTheUnitFromTheButtonBelow', '下記ボタンからユニットの登録をしてください。')}</span>
            <br />
            <Button variant='contained' type='submit' onClick={handleAddUnitButton}>
              {t('RegisterUnit', 'ユニット登録ページへ')}
            </Button>
          </div>
        )}
      </PanelContainer>
      {isOpenDeleteDialog && resident && (
        <Dialog
          isOpen
          title={t('deleteResident', '入居者削除')}
          onClose={handleCloseDeleteDialog}
          onOkClick={deleteResident}
        >
          <div css={DialogContainer}>
            <div css={DialogText}>
              {t('confirmDeleteResident1', '今後、削除された入居者の情報を見ることはできなくなります。')}
              <br />
              {t('confirmDeleteResident2', '下記の入居者を削除しますか？')}
            </div>
            <div css={DialogAlertText}>
              {resident.lastName}
              {resident.name} {resident.nursingHomeUnitName && <> - {resident.nursingHomeUnitName}</>}
            </div>
          </div>
        </Dialog>
      )}
      {isOpenAttachedDialog && (
        <Dialog isOpen title='' onClose={handleCloseAttachedDialog} onOkClick={handleSubmit(submit)}>
          <div css={DialogContainer}>
            {i18n.language === 'ja' && (
              <>
                <div css={DialogText}>
                  {selectData?.devices.find((device) => device.id === +deviceId)?.lastName}さんのDFreeを
                  {resident?.lastName || lastName}さんに紐付け変更しますがよろしいですか？
                </div>
              </>
            )}
            {i18n.language !== 'ja' && (
              <>
                <div css={DialogText}>
                  Are you sure you want to link{' '}
                  {selectData?.devices.find((device) => device.id === +deviceId)?.lastName}’s DFree to{' '}
                  {resident?.lastName}?
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};
